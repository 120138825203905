<template>
    <el-popover placement="bottom">
      <el-button style="margin-left: 10px;" slot="reference" type="primary" size="mini">Upload</el-button>
      <el-form>
        <el-form-item label="">
           <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :data="{libraryType: libraryType}"
            :limit="1"
            :headers="{ token }"
            :on-success="uploadSuccess"
            :on-error="e => $message.error('Upload failed, Please check file')"
            :file-list="fileList">
          <el-button size="small" type="primary">Upload File</el-button>
        </el-upload>
        </el-form-item>
      </el-form>
    </el-popover>
</template>
<script>
import {mapGetters} from 'vuex'
import { uploadLang, uploadTournament } from '@/service/standard'
export default {
  props: {
    libraryType: {
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      downloding: false,
      uploadUrl: '',
      fileList: [],
    }
  },
  mounted() {
    this.uploadUrl = uploadLang();
  },
  computed: {
  ...mapGetters(['token'])
  },
  methods: {
       uploadSuccess() {
      document.body.click()
      this.$emit('success')
      this.fileList = []
    },
  }
}
</script>